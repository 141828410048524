const routes = [
    {
        path: 'account',
        component: () => import(/* webpackChunkName: "App_Account_Home" */ '@/views/app/account/accountHome.vue')
    },
    {
        path: 'profile',
        component: () => import(/* webpackChunkName: "App_Account_Profile" */ '@/views/app/account/profile.vue')
    },
    {
        path: 'subscription',
        component: () => import(/* webpackChunkName: "App_Account_Subscription" */ '@/views/app/account/subscription.vue')
    },
    {
        path: 'payment-history',
        component: () => import(/* webpackChunkName: "App_Account_Payment_History" */ '@/views/app/account/paymentHistory.vue')
    }
]

export default routes