import Vue from 'vue';
import VueI18n from 'vue-i18n'
import en from "@/locales/en"
import hi from "@/locales/hi"

Vue.use(VueI18n);

const messages = {
    en: en,
    hi: hi
}

export default new VueI18n({
    locale: 'en', // set locale
    messages, // set locale messages
})
