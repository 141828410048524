const routes = [
  {
    path: 'objective-test/:testId',
    component: () => import(/* webpackChunkName: "App_Objective_Tests" */ '@/views/app/tests/objective/ObjectiveTestDetails.vue'),
  },
  {
    path: 'objective-test/:testId/instructions',
    component: () => import(/* webpackChunkName: "App_Objective_Tests_Instructions" */ '@/views/app/tests/objective/ObjectiveTestInstruction.vue'),
  },
  {
    path: 'objective-test/:testId/attempt',
    component: () => import(/* webpackChunkName: "App_Objective_Tests_Attempt" */ '@/views/app/tests/objective/ObjectiveTest.vue'),
  },
  {
    path: 'objective-test/:testId/results/:attemptId',
    component: () => import(/* webpackChunkName: "App_Objective_Tests_Results" */ '@/views/app/tests/objective/ObjectiveTestResults.vue'),
  },
]

export default routes
