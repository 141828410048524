const routes = [
  {
    path: 'typo',
    component: () => import(/* webpackChunkName: "Typography" */ '@/views/zTests/Typography.vue'),
  },
  {
    path: 'buttons',
    component: () => import(/* webpackChunkName: "Buttons" */ '@/views/zTests/Buttons.vue'),
  },
  {
    path: 'form',
    component: () => import(/* webpackChunkName: "FormInputs" */ '@/views/zTests/FormInputs.vue'),
  },
  {
    path: '*',
    redirect: 'typo'
  },
  {
    path: '/',
    redirect: 'typo'
  }
]

export default routes
