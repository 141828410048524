import keys from "@/constants/keys";
import Vue from "vue";
import VueAnalytics from "vue-analytics";
import router from "@/router";

Vue.use(VueAnalytics, {
  id: keys.googleTrackingId,
  router,
  debug: {
    sendHitTask: process.env.NODE_ENV === "production",
  },
  autoTracking: {
    pageviewTemplate(route) {
      return {
        page: route.path,
        title: document.title,
        location: window.location.href,
      };
    },
  },
});
