import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";
import time from "./mixins/time";
import "./plugins/db";
import "./registerServiceWorker";
import firebase from "firebase/app";
import "firebase/analytics";
import "./plugins/analytics";

Vue.config.productionTip = false;

Vue.mixin(time);
Vue.mixin(
  (() => {
    let store = Vue.observable({
      showGlobalErrorMessage: false,
      globalErrorMessage: "",
    });

    return {
      computed: {
        globalErrorMessage: {
          get() {
            return store.globalErrorMessage;
          },
          set(val) {
            store.globalErrorMessage = val;
          },
        },
        showGlobalErrorMessage: {
          get() {
            return store.showGlobalErrorMessage;
          },
          set(val) {
            store.showGlobalErrorMessage = val;
          },
        },
      },
      methods: {
        showGlobalError(message) {
          this.globalErrorMessage = message;
          this.showGlobalErrorMessage = true;
        },
      },
    };
  })()
);

var firebaseConfig = {
  apiKey: "AIzaSyCiNjDLx9mx-ghlDwfS9McNEbhcIXUpRK0",
  authDomain: "mobishaala-84ff5.firebaseapp.com",
  databaseURL: "https://mobishaala-84ff5.firebaseio.com",
  projectId: "mobishaala-84ff5",
  storageBucket: "mobishaala-84ff5.appspot.com",
  messagingSenderId: "488057792591",
  appId: "1:488057792591:web:956cb0abd05bca23e1623a",
  measurementId: "G-14JV0JCEW1",
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

new Vue({
  router,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
