const routes = [
  {
    path: 'video',
    component: () => import(/* webpackChunkName: "App_Home_Video" */ '@/views/app/home/Video.vue')
  },
  {
    path: 'tests',
    component: () => import(/* webpackChunkName: "App_Home_Tests" */ '@/views/app/home/Tests.vue'),
    children: [
      {
        path: 'objective',
        component: () => import(/* webpackChunkName: "App_Home_Tests_Objective" */ '@/views/app/home/Objective.vue'),
      },
      {
        path: 'subjective',
        component: () => import(/* webpackChunkName: "App_Home_Tests_Subjective" */ '@/views/app/home/Subjective.vue'),
      },
      {
        path: '*',
        redirect: 'objective'
      },
      {
        path: '/',
        redirect: 'objective'
      }
    ]
  },
  {
    path: 'reads',
    component: () => import(/* webpackChunkName: "App_Home_Reads" */ '@/views/app/home/Reads.vue'),
    children: [
      {
        path: 'notes',
        component: () => import(/* webpackChunkName: "App_Home_Reads_Notes" */ '@/views/app/home/Notes.vue'),
      },
      {
        path: 'ebooks',
        component: () => import(/* webpackChunkName: "App_Home_Reads_eBooks" */ '@/views/app/home/eBooks.vue'),
      },
      {
        path: '*',
        redirect: 'notes'
      },
      {
        path: '/',
        redirect: 'notes'
      }
    ]
  },
  {
    path: 'settings',
    component: () => import(/* webpackChunkName: "App_Home_Settings" */ '@/views/app/home/Settings.vue')
  },
  {
    path: '*',
    redirect: 'video'
  },
  {
    path: '/',
    redirect: 'video'
  }
]

export default routes
