import Vue from "vue";
import VueRouter from "vue-router";
import Start from "../views/Start.vue";
import tests from "../router/tests.js";
import app from "../router/app.js";
import clazz from "../router/class.js";
import link from "../router/link.js";
import publication from "../router/publication.js";
import discover from "../router/discover.js";

import NProgress from "nprogress";
import "@/assets/css/nprogress.css";

Vue.use(VueRouter);

const routes = [
  {
    path: "/start",
    component: Start,
  },
  {
    path: "/app",
    component: () =>
      import(/* webpackChunkName: "AppHome" */ "@/views/app/AppHome.vue"),
    children: app,
  },
  {
    path: "/class",
    component: () =>
      import(/* webpackChunkName: "ClassHome" */ "@/views/class/ClassHome.vue"),
    children: clazz,
  },
  {
    path: "/test",
    component: () =>
      import(/* webpackChunkName: "TestHome" */ "@/views/zTests/TestHome.vue"),
    children: tests,
  },
  {
    path: "/link",
    component: () =>
      import(/* webpackChunkName: "LinkHome" */ "@/views/link/LinkHome.vue"),
    children: link,
  },
  {
    path: "/publication",
    component: () =>
      import(
        /* webpackChunkName: "PublicationHome" */ "@/views/publication/PublicationHome.vue"
      ),
    children: publication,
  },
  {
    path: "/discover",
    component: () => import("@/views/discover/DiscoverHome.vue"),
    children: discover,
  },
  {
    path: "/publication-list/:instituteId",
    redirect: (to) => ({
      path: "/publication/list/" + to.params.instituteId,
    }),
  },
  {
    path: "/publication-objective/:testId/:questionId",
    redirect: (to) => ({
      path:
        "/publication/objective/" +
        to.params.testId +
        "/" +
        to.params.questionId,
    }),
  },
  {
    path: "*",
    redirect: "start",
  },
  {
    path: "/",
    redirect: "start",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  NProgress.start();
  console.log(
    "from   %c" + from.path + "%c   to   %c" + to.path,
    "background-color:#218c74;font-weight: 200; color:white; padding: 4px 16px; border-radius:4px;",
    "",
    "background-color:#218c74;font-weight: 200; color:white; padding: 4px 16px; border-radius:4px;"
  );
  if (from.path != to.path) {
    next();
  }
});

router.afterEach(() => {
  NProgress.done();
});
export default router;
