const routes = [
  {
    path: 'ebook/:bookId',
    component: () => import(/* webpackChunkName: "App_eBooks" */ '@/views/app/reads/EBookDetails.vue'),
  },
  {
    path: 'note/:noteId',
    component: () => import(/* webpackChunkName: "App_Notes" */ '@/views/app/reads/NoteDetails.vue'),
  },
  {
    path: 'book-viewer/:id',
    component: () => import(/* webpackChunkName: "App_BookViewer" */ '@/views/app/reads/BookViewer.vue'),
  },
]

export default routes
