<template>
  <div class="home">
    <v-img
      class="img"
      :class="{ full: animate }"
      alt="Mobishaala logo"
      contain
      ref="image"
      src="@/assets/img/text_logo.png"
    />
  </div>
</template>

<script>
import Storage from "@/constants/StorageKeys.js";

export default {
  data: () => ({
    animate: false,
  }),
  mounted() {
    let vm = this;
    setTimeout(() => {
      vm.animate = true;
      vm.start();
    }, 200);
  },
  methods: {
    start() {
      let vm = this;
      setTimeout(() => {
        if (!localStorage.getItem(Storage.token))
          vm.$router.replace("/app/login");
        else if (!localStorage.getItem(Storage.currentInstituteId))
          vm.$router.replace("/app/institutes_list");
        else
          vm.$router.replace("/app/home");
      }, 3000);
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.home .img {
  width: 70%;
  max-width: 400px;
  transform: scale(0.8);
  opacity: 0;
  transition: transform 3s cubic-bezier(0.14, 0.48, 0.48, 0.79),
    opacity 2s cubic-bezier(0.14, 0.48, 0.48, 0.79);
}

.home .img.full {
  transform: scale(1);
  opacity: 1;
}
</style>