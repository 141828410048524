import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'md',
    },
    theme: {
        dark: false,
        themes: {
            light: {
                primary: '#8a0727',
                secondary: '#660727',
                accent: '#ffad00',
                appBar: '#ffffff',
                alwaysPrimary: '#8a0727',
            },
            dark: {
                primary: '#ffffff',
                secondary: '#8a0727',
                accent: '#ffad00',
                appBar: '#1e1e1e',
                alwaysPrimary: '#8a0727',
            }
        },
        // options: {
        //     themeCache: {
        //         get: key => localStorage.getItem(key),
        //         set: (key, value) => localStorage.setItem(key, value),
        //     },
        // },
    },
});
