const routes = [
  {
    path: "feed",
    component: () =>
      import(/* webpackChunkName: "Discover" */ "@/views/discover/Feed.vue"),
  },
  {
    path: "notes",
    component: () =>
      import(/* webpackChunkName: "Discover" */ "@/views/discover/Notes.vue"),
  },
  {
    path: "quiz",
    component: () =>
      import(/* webpackChunkName: "Discover" */ "@/views/discover/Quizes.vue"),
  },

  {
    path: "*",
    redirect: "/",
  },
];

export default routes;
