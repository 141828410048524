import Vue from 'vue';
import Vlf from 'vlf'
import localforage from 'localforage'

localforage.config({
    name        : 'com.mobishaala.app',
    version     : 1.0,
    storeName   : 'ms_keyvaluepairs'
});

Vue.use(Vlf, localforage)
