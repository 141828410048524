const routes = [
  {
    path: 'home',
    component: () => import(/* webpackChunkName: "Class_Home" */ '@/views/class/Home.vue')
  },
  {
    path: 'room/:meetingId',
    component: () => import(/* webpackChunkName: "Class_Room" */ '@/views/class/Room.vue')
  },
  {
    path: '*',
    redirect: 'home'
  },
  {
    path: '/',
    redirect: 'home'
  }
]

export default routes
