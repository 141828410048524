export default {
    locale_name: "English",
    app_name: "Mobishaala",
    tos: 'By continuing, you confirm that you have read and agree to our <a href="http://mobishaala.com/files/toe.pdf">Terms and Conditions</a> and <a href="http://mobishaala.com/files/pp.pdf">Privacy Policy</a>.',
    watch_video_lectures_take_tests_ebooks_and_more: "Learn from your favourite faculty",
    sign_in: "Sign In",
    next: "Next",
    activity_sign_in: "Sign In",
    mobile_number: "Mobile Number",
    enter_10_digit_number: "Enter 10 digit number",
    sign_in_register_to_continue: "Sign in / Register to continue",
    otp: "OTP",
    enter_6_digit_OTP: "Enter 6 digit OTP",
    otp_sent_to_mobile_number: "OTP sent to mobile number",
    resend_otp: "Resend OTP",
    didn_t_receive_otp: "Didn't receive OTP ?",
    edit_number: "Edit number",
    please_wait_to_resend_OTP: "Please wait to resend OTP.",
    confirm: "Confirm",
    we_will_be_sending_otp_to_this_number: "We will be sending OTP to mobile number",
    send: "Send",
    cancel: "Cancel",
    show_all: "MORE",
    otp_sent: "OTP Sent",
    student_profile: "Student Profile",
    complete_your_profile_for_a_more_personalised_experience: "Complete your profile for a more personalised experience",
    last_name: "Last name",
    first_name: "First Name",
    shipping_address: "Shipping Address",
    house_flat_street: "House/Flat, Street",
    city: "City",
    state: "State",
    pincode: "Pincode",
    email: "Email",
    verify_number: "Verify Number",
    enter_institute_code: "Enter Institute Code",
    use_your_institute_code_to_enrol_now_and_start_learning: "Use your Institute code to enrol now and start learning.",
    institute_code: "Institute Code",
    enter_8_digit_code: "Enter 8 digit Institute Code",
    discover_institutes: "Discover Institutes",
    or: "Or",
    add_new_institute: "Add new Institute",
    enrolled_institutes: "Enrolled Institutes",
    video: "Video",
    tests: "Tests",
    reads: "Reads",
    settings: "Settings",
    watch_lectures: "Watch Lectures",
    click_here_for_more: "Click here for more",
    like: "Like",
    download: "Download",
    share: "Share",
    description: "Description",
    bookmark: "Bookmark",
    bookmarks: "Bookmarks",
    continue_watching: "Continue Watching",
    not_available: "Not Available",
    loading: "Loading",
    start_reading: "Start Reading",
    preparing: "Preparing",
    downloading: "Downloading",
    download_paused: "Download Paused",
    download_cancelled: "Download Cancelled",
    download_error: "Download Error",
    continue_reading: "Continue Reading",
    no_videos_yet_come_back_here_later: "No Videos Yet. Come back here later.",
    questions: "Questions",
    test_time: "Time",
    total_marks: "Total Marks",
    take_test: "Take Test",
    continue_test: "Continue Test",
    start_test: "Start Test",
    submit: "Submit",
    clear: "Clear",
    choose_the_option_that_best_answers_the_question: "Choose the option that best answers the question.",
    skip: "Skip",
    attempted: "Attempted",
    not_attempted: "Not attempted",
    review: "Review",
    unseen: "Unseen",
    attempts: "Attempts",
    about: "About",
    share_app: "Share app",
    help: "Help",
    feedback: "Feedback",
    dark_mode: "Dark mode",
    account: "Account",
    institutions: "Discover Institutes",
    plans: "Plans",
    add_answer: "Add Answer",
    no_plans: "No plans.",
    show_all_plans: "Show all Plans",
    following_contents_are_available_with_this_plan: "Following contents are available with this plan",
    videos: "Videos",
    make_payment: "Make Payment",
    buy: "Buy",
    goto_plans: "Goto Plans",
    paid_lecture: "Paid Lecture",
    paid_lecture_message: "This lecture is paid. Please see the list of plans to purchase this content and support your faculty.",
    logout: "Logout",
    are_you_sure_you_want_to_logout: "Are you sure you want to logout?",
    coupons: "Coupons",
    session_ended_please_login: "Session ended. Please login.",
    something_went_wrong: "Something went wrong",
    server_error: "Server error",
    file_not_found_please_download_again: "File Not Found. Please download again.",
    skip_to_page: "Skip to Page",
    enter_page_number: "Enter Page Number",
    wrong_page_number: "Wrong Page number",
    enter_page_number_between: "Enter page number between",
    rename: "Rename",
    set_a_name_for_bookmark: "Set a name for your bookmark",
    name: "Name",
    delete_download: "Delete Download",
    do_you_want_to_delete_download: "Do you want to delete the download?",
    deleted: "Deleted",
    select_institute: "Select Institute",
    notifications: "Notifications",
    are_you_sure_you_want_to_start_test: "Are you sure you want to start the test?",
    start: "Start",
    are_you_sure_you_want_to_submit: "Are you sure you want to submit?",
    test_submitted: "Test Submitted",
    you_ran_out_of_time: "You ran out of time",
    correct: "Correct",
    incorrect: "Incorrect",
    Result: "Result",
    solution: "Solution",
    payment_history: "Payment History",
    plan_not_found: "Plan not found",
    apply_coupon: "Apply Coupon",
    payment_failed: "Payment failed.",
    network_error_check_internet_connection_and_try_again: "Network error. Check internet connection and try again.",
    user_cancelled_paymnet: "User cancelled payment.",
    cannot_make_secure_payment_using_device: "Cannot make secure payment using device.",
    payment_successfull_goto_home_to_see_content: "Payment successful. Goto home screen to see your content.",
    payment_failed_try_again: "Payment failed. Try again.",
    plan: "Plan",
    plan_already_purchased: "Plan already purchased.",
    notes: "Notes",
    ebooks: "eBooks",
    do_you_want_to_delete_the_download: "Do you want to delete the download?",
    submit_test: "Submit Test",
    quit_test: "Quit Test",
    are_you_sure_you_want_to_quit_test: "Are you sure you want to quit?",
    quit: "Quit",
    preview: "Preview",
    delete_answer: "Delete answer",
    are_you_sure_you_want_to_delete_this_image: "Are you sure you want to delete this image?",
    delete: "Delete",
    submit_answer: "Submit Answer",
    your_device_needs_camera_to_upload_images: "Your device needs camera to upload images.",
    uploading: "Uploading",
    submitted: "Submitted",
    you_can_also_send_a_short_remark_for_the_reviewer: "You can also send a short remark for the reviewer",
    enter_remark: "Enter Remark",
    quit_submission: "Quit Submission",
    are_you_sure_you_want_to_quit_submission_for_this_test: "Are you sure you want to quit submission for this test?",
    remove_download: "Remove Download",
    are_you_sure_you_want_to_remove_downloaded_video: "Are you sure you want to remove the downloaded video?",
    yes: "Yes",
    subscription: "Subscription",
    objective: "Objective",
    subjective: "Subjective",
    enter_name: "Enter name",
    enter_email: "Enter email",
    enter_address: "Enter address",
    enter_6_digit_pincode: "Enter 6 digit pincode",
    mobishaala_is_out_of_date_update_now_to_get_latest_features: "Mobishaala is out of date. Update now to get latest features.",
    update: "Update",
    update_required: "Update Required",
    no_tests_available: "No Tests Available",
    no_ebooks_available: "No eBooks Available",
    no_notes_available: "No Notes Available",
    view_submission: "View Submission",
    submission: "Submission",
    your_faculty_will_correct_the_submission_soon: "Your faculty will correct the submission soon. Come back here later.",
    your_faculty_has_corrected_your_submission: "Your faculty has corrected your submission. Your total marks is ",
    remark: "Remark",
    you_have_made_a_submission_on: "You have made a submission on",
    attempt: "Attempt",
    correction: "Correction",
    view_correction: "View Correction",
    view_test_paper: "View Test Paper",
    fees: "Fees : ",

    about_us: "About Us",
    contact_us: "Contact Us",
    terms_of: "Terms of Use",
    privacy_policy: "Privacy Policy",
    cancellation_refunds: "Cancellation and Refunds",
    disclaimer: "Disclaimer",
    process_flow: "Process Flow",

    validity: "Validity",
    plan_validity_is: "Plan validity is",
    days_in_total: "days in total",
    mrp: 'MRP',
    discount: 'Discount',
    incl_gst: 'incl GST',


    edit: 'Edit',
    failed_to_upload: "Failed to upload the images.",
    retry_upload: "Do you want to try?",
    stop_and_close_test: "Cancel and Finish the test",
    retry: "Retry",

    question: "Question",
    marks: "Marks",

    subsribed_on: "Subscribed On",
    expiry_on: "Expiry On",
    order_id: "Order Id",
    status: "Staus",
    paid: 'Paid',
    not_paid: "Not Paid",
    amount: "Amout",

    exam_focus: "Exam Focus",
    is_working: "Working professional",
    medium: "Medium",
    optional_subject: "Optional Subject",


}