import moment from "moment";
export default {
    methods: {
        formatDate(dateString, format = 'MMMM Do YYYY, h:mm:ss a') {
            return moment(dateString).locale(this.$i18n.locale).format(format)
        },
        formatTimeFromSeconds(seconds, format = 'H:mm:ss') {
            // return moment("2015-01-01").startOf('day')
            //     .seconds(seconds)
            //     .format(format);

                return  moment("1900-01-01 00:00:00").add(seconds, 'seconds').format(format);
        },
        formatTimeFromSecondsAlt(seconds){
                var d = Number(seconds);
                var h = Math.floor(d / 3600);
                var m = Math.floor(d % 3600 / 60);

                return h+" hr "+m+" min"
        }
    }
}