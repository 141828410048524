const routes = [
  {
    path: "404",
    component: () =>
      import(
        /* webpackChunkName: "Link_Broken" */ "@/views/link/BrokenLink.vue"
      ),
  },
  {
    path: ":instituteId/video/:subjectId/:chapterId/:topicId?",
    component: () =>
      import(
        /* webpackChunkName: "Link_Video" */ "@/views/link/ChapterList.vue"
      ),
  },
  {
    path: ":instituteId/ttp/:noteBook/:pageId",
    component: () =>
      import(
        /* webpackChunkName: "Link_Video" */ "@/views/link/TTPVideoLink.vue"
      ),
  },
  // {
  //   path: ":instituteId/video/:subjectId/:chapterId/",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "Link_Video" */ "@/views/link/ChapterList.vue"
  //     ),
  // },
  {
    path: ":instituteId",
    component: () =>
      import(
        /* webpackChunkName: "Link_Institute" */ "@/views/link/Institute.vue"
      ),
  },
  {
    path: "*",
    redirect: "404",
  },
  {
    path: "/",
    redirect: "404",
  },
];

export default routes;
