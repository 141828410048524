<template>
  <v-app>
    <router-view />

    <v-snackbar bottom right :value="updateExists" :timeout="-1">
      An update is available
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="refreshApp">
          Update
        </v-btn>
      </template>
    </v-snackbar>
    
    <v-snackbar bottom right v-model="showGlobalErrorMessage" :timeout="2000">
      {{ globalErrorMessage }}
    </v-snackbar>
  </v-app>
</template>

<script>
import mix_update from "@/mixins/update";
export default {
  name: "App",
  mixins: [mix_update],
  data: () => ({
    //
  }),
};
</script>
