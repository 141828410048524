const routes = [
  {
    path: 'plans',
    component: () => import(/* webpackChunkName: "App_Plan" */ '@/views/app/plans/PlansHome.vue')
  },
  {
    path: 'plan/:planId',
    component: () => import(/* webpackChunkName: "App_Plan_Details" */ '@/views/app/plans/PlanDetails.vue')
  },
  {
    path: 'plan/:planId/purchase',
    component: () => import(/* webpackChunkName: "App_Plan_Purchase" */ '@/views/app/plans/PlanPayment.vue')
  },
]

export default routes
