const routes = [
  {
    path: 'subjective-test/:testId',
    component: () => import(/* webpackChunkName: "App_Subjective_Tests" */ '@/views/app/tests/subjective/SubjectiveTestDetails.vue'),
  },
  {
    path: 'subjective-test-instruction/:testId',
    component: () => import(/* webpackChunkName: "App_Subjective_Tests_Instruction" */ '@/views/app/tests/subjective/SubjectiveTestInstruction.vue'),
  },
  {
    path: 'subjective-test-questions/:testId',
    component: () => import(/* webpackChunkName: "App_Subjective_Tests_Questions" */ '@/views/app/tests/subjective/SubjectiveTestQuestionPaper.vue'),

  },
  {
    path: 'subjective-test-upload-answer/:testId',
    component: () => import(/* webpackChunkName: "App_Subjective_Tests_Upload_Answer" */ '@/views/app/tests/subjective/SubjectiveTestUploadImages.vue'),

  },
  {
    path: 'subjective-test-previous-submission/:testId',
    component: () => import(/* webpackChunkName: "App_Subjective_Tests_Previous_Submission" */ '@/views/app/tests/subjective/subjectiveSubmittedImages.vue'),

  },
  {
    path: 'subjective-test-correction/:testId',
    component: () => import(/* webpackChunkName: "App_Subjective_Tests_Correction" */ '@/views/app/tests/subjective/subjectiveCorrected.vue'),

  }

]

export default routes
