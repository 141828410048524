const routes = [
  {
    path: 'subject/:subjectId',
    component: () => import(/* webpackChunkName: "App_Subjects" */ '@/views/app/subjects/SubjectDetails.vue'),
  },
  {
    path: 'subject/:subjectId/watch',
    component: () => import(/* webpackChunkName: "App_Subjects_Watch" */ '@/views/app/subjects/SubjectPlayer.vue'),
  },
]

export default routes
