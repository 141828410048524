const routes = [
  {
    path: 'login',
    component: () => import(/* webpackChunkName: "App_Login_Home" */ '@/views/app/login/LoginHome.vue')
  },
  {
    path: 'auth',
    component: () => import(/* webpackChunkName: "App_Login_Authenticate" */ '@/views/app/login/Authenticate.vue')
  },
  {
    path: 'enrol',
    component: () => import(/* webpackChunkName: "App_Login_Enrol" */ '@/views/app/login/InstituteList.vue')
  },
  {
    path: 'institutes_list',
    component: () => import(/* webpackChunkName: "App_Institute_Select" */ '@/views/app/login/InstituteList.vue')
  },
  {
    path: 'add_institute_code',
    component: () => import(/* webpackChunkName: "App_Institute_Add" */ '@/views/app/login/InstituteCode.vue')
  },
]

export default routes
