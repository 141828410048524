export default {
    locale_name: "हिंदी",
    app_name: "Mobishaala",
    tos: 'जारी रखकर, आप पुष्टि करते हैं कि आपने पढ़ा है और हमारे लिए सहमत हैं<a href="http://mobishaala.com/files/toe.pdf"> नियम और शर्तें</a> तथा<a href="http://mobishaala.com/files/pp.pdf"> गोपनीयता नीति</a> ',
    watch_video_lectures_take_tests_ebooks_and_more: "अपने पसंदीदा शिक्षक से पढ़े ",
    sign_in: "साइन इन करें",
    next: "आगे",
    activity_sign_in: "साइन इन करें",
    mobile_number: "मोबाइल नंबर",
    enter_10_digit_number: "10 अंकों की संख्या दर्ज करें",
    sign_in_register_to_continue: "जारी रखने के लिए साइन /इन करें",
    otp: "ओटीपी",
    enter_6_digit_OTP: "6 अंक ओटीपी दर्ज करें",
    otp_sent_to_mobile_number: "मोबाइल नंबर पर भेजा गया ओटीपी",
    resend_otp: "ओटीपी पुनः भेजें",
    didn_t_receive_otp: "क्या OTP प्राप्त नहीं हुआ?",
    edit_number: "नंबर संपादित करें",
    please_wait_to_resend_OTP: "कृपया OTP को पुनः भेजने की प्रतीक्षा करें।",
    confirm: "पुष्टि करें",
    we_will_be_sending_otp_to_this_number: "मोबाइल नंबर पर ओटीपी भेजा जाएगा",
    send: "भेजना",
    cancel: "रद्द करना",
    show_all: "अधिक",
    otp_sent: "ओटीपी भेजा गया",
    student_profile: "छात्र प्रोफाइल",
    complete_your_profile_for_a_more_personalised_experience: "अधिक व्यक्तिगत अनुभव के लिए अपनी प्रोफ़ाइल को पूरा करें",
    last_name: "उपनाम",
    first_name: "पहला नाम",
    shipping_address: "शिपिंग पता",
    house_flat_street: "घर /फ्लैट, सड़क",
    city: "शहर",
    state: "राज्य",
    pincode: "पिन कोड",
    email: "ईमेल",
    verify_number: "नंबर सत्यापित करें",
    enter_institute_code: "संस्थान कोड दर्ज करें",
    use_your_institute_code_to_enrol_now_and_start_learning: "अभी नामांकित करने और सीखना शुरू करने के लिए अपने संस्थान कोड का उपयोग करें।",
    institute_code: "संस्थान कोड",
    enter_8_digit_code: "8 अंक संस्थान कोड दर्ज करें",
    discover_institutes: " संस्थान खोजें ",
    or: "या",
    add_new_institute: "नया संस्थान जोड़ें",
    enrolled_institutes: "दाखिला संस्थान",
    video: "वीडियो",
    tests: "टेस्ट",
    reads: "पुस्तकें",
    settings: "समायोजन",
    watch_lectures: "देखिए व्याख्यान",
    click_here_for_more: "अधिक  के लिए यहां क्लिक करें",
    like: "पसंद",
    download: "डाउनलोड",
    share: "शेयर",
    description: "विवरण",
    bookmark: "बुकमार्क",
    bookmarks: "बुकमार्क",
    continue_watching: "देखना जारी रखें",
    not_available: "उपलब्ध नहीं है",
    loading: "लोड हो रहा है",
    start_reading: "पढ़ना शुरू करें",
    preparing: "तैयार कर रहे हैं",
    downloading: "डाउनलोड कर रहा है",
    download_paused: "डाउनलोड रोका गया",
    download_cancelled: "डाउनलोड रद्द किया गया",
    download_error: "डाउनलोड त्रुटि",
    continue_reading: "पढ़ना जारी रखें",
    no_videos_yet_come_back_here_later: "कोई वीडियो नहीं। बाद में यहां लौट आएं।",
    questions: "प्रशन",
    test_time: "समय",
    total_marks: "कुल मार्क",
    take_test: "टेस्ट लो",
    continue_test: "टेस्ट जारी रखें",
    start_test: "टेस्ट शुरू करें",
    submit: "प्रस्तुत",
    clear: "स्पष्ट",
    choose_the_option_that_best_answers_the_question: "उस विकल्प को चुनें जो सवाल का सबसे अच्छा जवाब देता है।",
    skip: "छोड़ें",
    attempted: " प्रयास किया गया",
    not_attempted: "प्रयास नहीं किया",
    review: "समीक्षा",
    unseen: "देखा नहीं गया ",
    attempts: "प्रयास",
    about: "के बारे में",
    share_app: "ऐप शेयर करें",
    help: "मदद",
    feedback: "आपकी प्रतिक्रिया",
    dark_mode: "डार्क मोड",
    account: "लेखा",
    institutions: "खोजें संस्थान",
    plans: "योजना",
    add_answer: "उत्तर जोड़ें",
    no_plans: "कोई योजना नहीं।",
    show_all_plans: "सभी योजनाएं दिखाएं",
    following_contents_are_available_with_this_plan: "इस योजना के साथ निम्नलिखित सामग्री उपलब्ध है",
    videos: "वीडियो",
    make_payment: "भुगतान करो",
    buy: "खरीदें",
    goto_plans: "योजनाओं पर जाएं",
    paid_lecture: "पेड लेक्चर",
    paid_lecture_message: "इस व्याख्यान का भुगतान किया जाता है। कृपया इस सामग्री को खरीदने के लिए योजनाओं की सूची देखें और अपने शिक्षक का समर्थन करें ।",
    logout: "लॉग आउट",
    are_you_sure_you_want_to_logout: "क्या आप लॉग आउट करना चाहते हैं?",
    coupons: "कूपन",
    session_ended_please_login: "सत्र समाप्त हुआ। कृपया लॉगिन करें।",
    something_went_wrong: "कुछ गलत हो गया",
    server_error: "सर्वर त्रुटि",
    file_not_found_please_download_again: "फाइल नहीं मिली। कृपया फिर से डाउनलोड करें।",
    skip_to_page: "पेज पर जाएं",
    enter_page_number: "पेज नंबर दर्ज करें",
    wrong_page_number: "गलत पृष्ठ संख्या",
    enter_page_number_between: "के बीच पेज नंबर दर्ज करें",
    rename: "नाम बदलें",
    set_a_name_for_bookmark: "अपने बुकमार्क के लिए एक नाम निर्धारित करें",
    name: "नाम",
    delete_download: "डाउनलोड हटाएं",
    do_you_want_to_delete_download: "क्या आप डाउनलोड हटाना चाहते हैं?",
    deleted: "हटाए गया",
    select_institute: "संस्थान का चयन करें",
    notifications: "सूचनाएं",
    are_you_sure_you_want_to_start_test: "क्या आप टेस्ट शुरू करना चाहते हैं?",
    start: "शुरू करें",
    are_you_sure_you_want_to_submit: "क्या आप वाकई सबमिट करने के इच्छुक हैं?",
    test_submitted: "टेस्ट प्रस्तुत किया",
    you_ran_out_of_time: "आपका समय पूर्ण हो चुका है",
    correct: "सही ",
    incorrect: "ग़लत",
    Result: "परिणाम",
    solution: "उपाय",
    payment_history: "भुगतान इतिहास",
    plan_not_found: "योजना नहीं मिली",
    apply_coupon: "कूपन लगाइये",
    payment_failed: "भुगतान असफल हुआ।",
    network_error_check_internet_connection_and_try_again: "नेटवर्क त्रुटि। इंटरनेट कनेक्शन की जाँच करें और पुनः प्रयास करें।",
    user_cancelled_paymnet: "उपयोगकर्ता ने भुगतान रद्द कर दिया।",
    cannot_make_secure_payment_using_device: "डिवाइस का उपयोग करके सुरक्षित भुगतान नहीं कर सकते।",
    payment_successfull_goto_home_to_see_content: "भुगतान सफल। अपनी सामग्री को देखने के लिए  होम स्क्रीन पर जाय ।",
    payment_failed_try_again: "भुगतान असफल हुआ। पुनः प्रयास करें।",
    plan: "योजना",
    plan_already_purchased: "पहले से खरीदे हुए प्लान।",
    notes: "नोट्स ",
    ebooks: "ई बुक्स",
    do_you_want_to_delete_the_download: "क्या आप डाउनलोड हटाना चाहते हैं?",
    submit_test: "टेस्ट जमा करें",
    quit_test: "टेस्ट से बाहर निकलें",
    are_you_sure_you_want_to_quit_test: "क्या आप वाकई छोड़ना चाहते हैं?",
    quit: "छोड़ना",
    preview: "पूर्वावलोकन",
    delete_answer: "उत्तर हटा दें",
    are_you_sure_you_want_to_delete_this_image: "क्या आप वास्तव में इस फोटो को मिटाना चाहते हैं?",
    delete: "हटाएं",
    submit_answer: "उत्तर सबमिट करें",
    your_device_needs_camera_to_upload_images: "फोटो को अपलोड करने के लिए आपके डिवाइस को कैमरा चाहिए।",
    uploading: "अपलोड हो रहा है",
    submitted: "प्रस्तुत",
    you_can_also_send_a_short_remark_for_the_reviewer: "आप समीक्षक के लिए एक छोटी टिप्पणी भी भेज सकते हैं",
    enter_remark: "टिप्पणी दर्ज करें",
    quit_submission: "प्रस्तुत करने से बाहर निकलें",
    are_you_sure_you_want_to_quit_submission_for_this_test: "क्या आप वाकई इस परीक्षण के लिए सबमिशन छोड़ना चाहते हैं?",
    remove_download: "डाउनलोड हटाये ",
    are_you_sure_you_want_to_remove_downloaded_video: "क्या आप वाकई डाउनलोड किए गए वीडियो को हटाना चाहते हैं?",
    yes: "हाँ",
    subscription: "खरीदे ",
    objective: "बहुविकल्पीय ",
    subjective: "विषयगत",
    enter_name: "नाम दर्ज",
    enter_email: "ईमेल दर्ज करें",
    enter_address: "पता लिखिए",
    enter_6_digit_pincode: "6 अंक पिनकोड दर्ज करें",
}