const routes = [
  {
    path: 'spt',
    component: () => import(/* webpackChunkName: "Publication_Test" */ '@/views/publication/SPTHome.vue')
  },
  {
    path: 'bpsc66m',
    component: () => import(/* webpackChunkName: "Publication_Test" */ '@/views/publication/BPSCLoading.vue')
  },
  {
    path: 'list/:instituteId',
    component: () => import(/* webpackChunkName: "Publication_Test" */ '@/views/publication/TestList.vue')
  },
  {
    path: 'profile',
    component: () => import(/* webpackChunkName: "Publication_Test" */ '@/views/publication/Profile.vue')
  },
  {
    path: 'objective/:testId',
    component: () => import(/* webpackChunkName: "Publication_Test" */ '@/views/publication/ObjectiveTest.vue')
  },
  {
    path: 'objective/:testId/:questionId',
    component: () => import(/* webpackChunkName: "Publication_Test" */ '@/views/publication/ObjectiveTest.vue')
  },
  {
    path: ':testId',
    component: () => import(/* webpackChunkName: "Publication_Test" */ '@/views/publication/Test.vue')
  },
  {
    path: ':testId/:questionId',
    component: () => import(/* webpackChunkName: "Publication_Test" */ '@/views/publication/Test.vue')
  },
  {
    path: '*',
    redirect: '/'
  }
]

export default routes
