import app_home from "@/router/app/home"
import app_subjects from "@/router/app/subjects"
import app_objective from "@/router/app/objective"
import app_subjective from "@/router/app/subjective"
import app_reads from "@/router/app/reads"
import app_login from "@/router/app/login"
import app_payment from "@/router/app/payment"
import app_account from '@/router/app/account'

const routes = [
  {
    path: 'home',
    component: () => import(/* webpackChunkName: "App_Home" */ '@/views/app/home/Home.vue'),
    children: app_home
  },
  ...app_subjects,
  ...app_objective,
  ...app_subjective,
  ...app_reads,
  ...app_login,
  ...app_payment,
  ...app_account,
  {
    path: 'about',
    component: () => import(/* webpackChunkName: "App_About" */ '@/views/app/about/AboutHome.vue')
  },

  {
    path: '*',
    redirect: 'home'
  },
  {
    path: '/',
    redirect: 'home/video'
  }
]

export default routes
